import { observer } from 'mobx-react-lite';
import React from 'react';

import { Layout, ReleaseModal, SystemStatusNotifications } from '@devdept/samolet-ui';
import { useVersion } from '@devdept/samolet-ui/lib/features/Header/hooks';
import { API_URLS } from 'constants/apiUrls';

import { useProfileStore } from 'stores';
import ErrorBoundary from 'components/ErrorBoundary';
import { IS_SAAS } from 'constants/common';
import { useGetConfigForLayout, useReleaseModal } from './hooks';

const Container: React.FC = observer(({ children }) => {
  const { user, backlink, resetRightsForPage } = useProfileStore();
  const { releaseModalVisible, setReleaseModalVisible } = useReleaseModal();
  const config = useGetConfigForLayout({
    user,
    backlink,
    resetRightsForPage,
    setReleaseModalVisible,
  });
  const { version } = useVersion();

  return (
    <Layout {...config}>
      <ErrorBoundary>
        {children}

        {!IS_SAAS && (
          <SystemStatusNotifications
            metadata={user!.metadata}
            actualSystemErrorApi={API_URLS.actualSystemError}
          />
        )}
        {/* 
        // TODO подключить, когда начнем выпускать новостные релизы
        {version && !IS_SAAS && (
          <ReleaseModal
            version={version.split('.', 3).join('.')}
            defaultVisible={releaseModalVisible}
            onVisibleChange={setReleaseModalVisible}
          />
        )} */}
      </ErrorBoundary>
    </Layout>
  );
});

export default Container;
