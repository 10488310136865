import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString, { ParsedQuery, StringifiableRecord } from 'query-string';

import { TQueryType } from 'types';

const options = { parseBooleans: false };

export const useQuery = () => {
  const location = useLocation();
  const history = useHistory();

  const query = useMemo(
    () => queryString.parse(location.search, options),
    [location.search, options],
  );

  const pushQuery = useCallback(
    (queryObj: TQueryType | ParsedQuery<string>) => {
      const modifiedQuery = {
        ...query,
        ...queryObj,
      } as StringifiableRecord;

      history.push({
        pathname: location.pathname,
        search: queryString.stringify(modifiedQuery),
      });
    },
    [history, location.pathname, query],
  );

  const replaceQuery = useCallback(
    (queryObj: TQueryType) => {
      const modifiedQuery = {
        ...query,
        ...queryObj,
      } as StringifiableRecord;

      history.replace({
        pathname: location.pathname,
        search: queryString.stringify(modifiedQuery),
      });
    },
    [history, location.pathname, query],
  );

  return { query, pushQuery, replaceQuery };
};
