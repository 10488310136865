import { useCallback, useState } from 'react';

import { captureException } from '@sentry/react';
import { EExportType, ESseStatus } from 'types';
import { exportXls, trailingSlash } from 'utils';
import { get, sseRequest } from 'api/request';

interface IRequestParams {
  search?: string;
  page?: number;
  limit?: number;
}

export const useExportXls = ({
  apiPrepare,
  apiExport,
  params,
}: {
  apiPrepare: string;
  apiExport: string;
  params?: IRequestParams;
}) => {
  const [exportInProgress, setExportInProgress] = useState(false);
  const [exportProgress, setExportProgress] = useState(0);

  const prepareExported = useCallback(async () => {
    try {
      const result: { taskId: string } = await get(apiPrepare, params);

      return result;
    } catch (e) {
      captureException(e);
      console.error(e);
      throw e;
    }
  }, [apiPrepare, params]);

  const createExportStream = useCallback(
    (taskId: string) => {
      try {
        return sseRequest(`${trailingSlash(apiExport)}${taskId}`, [
          ESseStatus.SUCCESS,
          ESseStatus.FAILURE,
          ESseStatus.REVOKED,
        ]);
      } catch (e) {
        captureException(e);
        console.error(e);
        throw e;
      }
    },
    [apiExport],
  );

  const onExportXls = useCallback(
    (exportType: EExportType = EExportType.DOWNLOAD) =>
      async () => {
        try {
          setExportInProgress(true);
          setExportProgress(0);
          const { taskId } = await prepareExported();
          const evtSource = createExportStream(taskId);
          exportXls(evtSource, setExportInProgress, setExportProgress, exportType);
        } catch (e) {
          captureException(e);
          setExportInProgress(false);
        }
      },
    [setExportInProgress, setExportProgress, prepareExported, createExportStream],
  );

  return {
    exportInProgress,
    exportProgress,
    onExportXls,
  };
};
