import { Loader } from '@devdept/samolet-ui';
import React from 'react';
import { Page } from './styles';

function PageLoader() {
  return (
    <Page>
      <Loader />
    </Page>
  );
}

export default PageLoader;
