import { OptionsFilterType } from '@devdept/samolet-ui';
import { OptionType } from '@devdept/samolet-ui/lib/components/Select';
import { SelectValue } from 'antd/lib/select';

export interface IObjectWithAnyKeys {
  [key: string]: any;
}

export interface IBaseEntity {
  id: number;
  name: string;
}

export enum ERriType {
  KIND = 'KIND',
  MATERIAL = 'MATERIAL',
  MATRIX_KIND = 'MATRIX_KIND',
  MATRIX_MATERIAL = 'MATRIX_MATERIAL',
  PROJECT_EVENT = 'PROJECT_EVENT',
  MATRIX = 'MATRIX',
  MATRIX_LOT = 'MATRIX_LOT',
  CHANGE_KIND = 'CHANGE_KIND',
  COPY_KIND_MAPPING = 'COPY_KIND_MAPPING',
  COPY_GROUP_MAPPING = 'COPY_GROUP_MAPPING',
  CHANGE_STATUS_KIND = 'CHANGE_STATUS_KIND',
  PROJECT = 'PROJECT',
  CHANGE_PROJECT = 'CHANGE_PROJECT',
  DELETE_MAPPING = 'DELETE_MAPPING',
}

export enum EProposalSourse {
  SCOST = 'scost',
  SVOLUME = 'svolume',
  RRI = 'rri',
}

export enum ESseStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  REVOKED = 'REVOKED',
  PENDING = 'PENDING',
  PROGRESS = 'PROGRESS',
}

export type TQueryType = {
  [name: string]: boolean | SelectValue | SelectValue[] | undefined;
};

export interface IListResponse<T> {
  count: number;
  results: T[];
  next: string | null;
  previous: string | null;
}

export enum EExportType {
  DOWNLOAD = 'DOWNLOAD',
  OPEN = 'OPEN',
}

export interface ISystemError {
  text: string;
  created: string;
}

export type TSystemStatus = 'error' | 'repaired' | null;

export interface IAddKindModalProps {
  initialValues?: IKindValues;
}

export interface IAddMaterialModalProps {
  initialValues?: IMaterialValues;
}

export interface IMaterialValues {
  argument: string;
  classifier: {
    id: number;
    label: string;
  };
  unit: {
    id: number;
    label: string;
  };
  vendorCode: string;
}

export interface IKindValues {
  kindFolder: number;
  kindName?: string;
  unit: number;
  name: string;
  fullName: string;
  argument: string;
}

export type FilterOptionsType = OptionType & {
  value: boolean;
};
