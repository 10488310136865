import { ReactText } from 'react';
import _ from 'lodash';
import { IAddEventModalProps } from 'features/ProposalView/types';
import {
  ERriType,
  IProfile,
  IProposalDetail,
  IRequestBodyItem,
  TStaticFields,
} from 'types';
import { IAddKindModalProps, IAddMaterialModalProps } from 'types/common';
import { IS_SAAS } from 'constants/common';

export const getCookie = (name: string): string | null =>
  document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(c => c.substring(0, name.length + 1) === `${name}=`)
    .map(c => decodeURIComponent(c.substring(name.length + 1)))[0] || null;

export const redirectToLogin = () => {
  if (IS_SAAS) {
    return;
  }
  const url = new URL('/accounts/login/samolet/', location.origin);
  url.searchParams.set('next', location.pathname);
  location.replace(url.toString());
};

export const trailingSlash = (str: string) => (str.endsWith('/') ? str : str + '/');

type SortingType = {
  order?: 'ascend' | 'descend' | null;
  field?: string | number | ReactText[];
};

export const getSorterValue = ({ order, field }: SortingType) => {
  if (!order || !field) return undefined;

  const orderValue = `${order === 'ascend' ? '' : '-'}${field}`;

  return orderValue;
};

const getSortOrder = (str?: string) => {
  if (!str) return false;

  return str[0] === '-' ? 'descend' : 'ascend';
};

export const setColumnsOrder = (columns: any[], order?: string | null) => {
  const validOrder = order?.replace('-', '');

  return columns.map(column => {
    return {
      ...column,
      sortOrder:
        validOrder === _.snakeCase(column.dataIndex)
          ? getSortOrder(order || undefined)
          : false,
    };
  });
};

export const getInitialValues = (
  response: IProposalDetail,
):
  | IAddKindModalProps['initialValues']
  | IAddMaterialModalProps['initialValues']
  | IAddEventModalProps['initialValues']
  | undefined => {
  const normalizedProps = response.requestBody.reduce((acc, item) => {
    return item.field ? { ...acc, [item.field]: item } : acc;
  }, {} as { [field in TStaticFields]: IRequestBodyItem });

  switch (response.elementType.id) {
    case ERriType.KIND: {
      const result: IAddKindModalProps['initialValues'] = {
        argument: response.argument,
        fullName: normalizedProps['fullName'].value,
        kindFolder: normalizedProps['kindFolder'].id as number,
        name: normalizedProps['name'].value,
        unit: normalizedProps['unit'].id as number,
      };

      return result;
    }
    case ERriType.MATERIAL: {
      const valuesByGuids = response.requestBody.reduce((acc, item) => {
        return item.field ? acc : { ...acc, [item.id]: item.value };
      }, {} as { [key: string]: string });

      const result: IAddMaterialModalProps['initialValues'] = {
        argument: response.argument,
        classifier: {
          id: normalizedProps['classifier'].id as number,
          label: normalizedProps['classifier'].value as string,
        },
        unit: {
          id: normalizedProps['unit'].id as number,
          label: normalizedProps['unit'].value as string,
        },
        vendorCode: normalizedProps['vendor_code']?.value || '',
        ...valuesByGuids,
      };

      return result;
    }
    case ERriType.PROJECT_EVENT: {
      const result: IAddEventModalProps['initialValues'] = {
        argument: response.argument,
        name: normalizedProps['name'].value,
        parentName: normalizedProps['parent'].value,
        parent: normalizedProps['parent'].id,
      };

      return result;
    }
    default:
      break;
  }
};

// export const getLink = (key: string, params?: StringifiableRecord) => {
//   const paramsQuery = queryString.stringify({ ...DEFAULT_LIMIT_PAGE, ...params });

//   return `${key}?${paramsQuery}`;
// };

export const getLink = (
  key: string,
  user: IProfile | null,
  limit: number,
  pathname?: string,
) => {
  const pageParams = user?.metadata?.pageQueryParams?.[key];
  const linkTo = pageParams
    ? `${key}${pageParams}&page=1`
    : `${key}?limit=${limit ? limit : 50}&page=1`;

  return pathname ? { pathname } : linkTo;
};
