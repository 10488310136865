import { AxiosError, AxiosRequestConfig } from 'axios';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/lib/upload/interface';

import { Toast } from '@10d/tend-ui/primitives';
import { captureException } from '@sentry/react';
import { APPROVE_FILES } from 'constants/typeFiles';
import { API_UPLOAD } from 'constants/apiUrls';

import { EScrollDirection } from 'features/ExtChat/types';
import { post, put } from 'api/request';
import { TOAST_MESSAGES } from 'constants/common';

export const useCustomUpload = () => {
  const onCustomUpLoad = async ({
    onError,
    onProgress,
    onSuccess,
    file,
  }: Partial<RcCustomRequestOptions>) => {
    try {
      const config: AxiosRequestConfig = {
        onUploadProgress: (event: ProgressEvent) => {
          onProgress &&
            onProgress({
              percent: (event.loaded / event.total) * 100,
              ...event,
            });
        },
      };

      const { fileName: filePath, uploadLink } = await post(
        API_UPLOAD.link,
        {
          fileName: (file as RcFile)?.name,
        },
        { disableSuccessMessages: true },
      );

      await put(`/${uploadLink.split('/').slice(3).join('/')}`, file, {
        ...config,
        headers: { 'Content-Type': (file as RcFile)?.type },
        disableSuccessMessages: true,
      });

      const mimeType = filePath.split('.').pop();

      const typeFile = APPROVE_FILES.filter(item => item.includes(mimeType)).join('');

      const response: { file: string; fileName: string; id: number; type: string } =
        await post(
          API_UPLOAD.attachment,
          {
            filePath,
            type: typeFile,
          },
          { disableSuccessMessages: true },
        );

      onSuccess && (onSuccess as (body: any) => void)(response);

      return response;
    } catch (err) {
      captureException(err);
      const error = err as AxiosError;
      if (error.response) {
        const { status } = error.response;
        if (status === 413) {
          Toast.error({
            message: TOAST_MESSAGES.maxFilesSizeError,
            duration: 5,
          });
        }
      }

      console.error(error);
      onError && onError(error);

      throw err;
    }
  };

  const mouseWheelHandler = (event: React.UIEvent<HTMLDivElement>): number => {
    return Math.max(
      -1,
      Math.min(
        1,
        (event.nativeEvent as any).wheelDelta ||
          -(event.nativeEvent as any).deltaY ||
          -event.nativeEvent.detail,
      ),
    );
  };

  const getWheelDirecton = (event: React.UIEvent<HTMLDivElement>): EScrollDirection => {
    const delta = mouseWheelHandler(event);
    if (delta < 0) {
      return EScrollDirection.DOWN;
    }

    return EScrollDirection.UP;
  };

  return {
    onCustomUpLoad: onCustomUpLoad,
    mouseWheelHandler: mouseWheelHandler,
    getWheelDirecton: getWheelDirecton,
  };
};
