import { TPermissionsProfile } from 'stores/Profile';
import { TPermissionsProposalView } from 'stores/ProposalView';

export const PERMISSIONS: {
  [key in TPermissionsProposalView | TPermissionsProfile]: string;
} = {
  canProposalAdd: 'proposals-proposal-add_proposal',
  canProposalEdit: 'change_proposal',
  canProposalReject: 'transition_to_rejected',
  canProposalClarify: 'transition_to_clarification',
  canProposalComplete: 'transition_to_completed',
  isAppraiser: 'proposals-proposal-transition_appraiser',
  canSeeClarifyAlert: 'proposals-proposal-transition_kind_methodologist',
  canEditProposalFields: 'edit_proposal_fields',
  canEditMatrixLotProposalFields: 'edit_matrix_lot_proposal',
  canEditWorkMapping: 'edit_kind_matrices',
  canEditChangeKind: 'edit_change_kind_proposal',
  canEditProject: 'edit_project_proposal',
  canEditMaterialMapping: 'edit_material_matrices',
  canEditCopyGroupMapping: 'edit_group_matrices',
  canEditChangeProject: 'edit_change_project_proposal',
  canEditEventProposalFields: 'edit_project_event_proposal',
  canSeeClarifyButtonForMetodologist: 'transition_to_previous_stage',
  canAddConstructionProject: 'proposals-proposal-transition_pmc',
};
