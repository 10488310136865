export const TYPE_FILE_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const TYPE_FILE_XLS = 'application/vnd.ms-excel';
export const TYPE_FILE_PDF = 'application/pdf';
export const TYPE_FILE_DOC = 'application/msword';
export const TYPE_FILE_PNG = 'application/png';
export const TYPE_FILE_JPEG = 'application/jpeg';
export const TYPE_FILE_JPG = 'application/jpg';
export const TYPE_FILE_TXT = 'application/txt';
export const TYPE_FILE_DOCx =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const APPROVE_FILES = [
  TYPE_FILE_XLSX,
  TYPE_FILE_XLS,
  TYPE_FILE_PDF,
  TYPE_FILE_PNG,
  TYPE_FILE_JPEG,
  TYPE_FILE_JPG,
  TYPE_FILE_DOC,
  TYPE_FILE_DOCx,
  TYPE_FILE_TXT,
];
