import { AndroidOutlined, DatabaseOutlined } from '@ant-design/icons';
import {
  ArchiveBookOutline,
  BackSquareOutline,
  BookOutline,
  DriverRefreshOutline,
  Grid6Outline,
  LayoutProps as ILayoutProps,
  SubMenuItem as ISubMenuItem,
  ShieldSearchOutline,
} from '@devdept/samolet-ui';
import { useLocation } from 'react-router';
import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { keycloakAuthService } from 'samolet-keycloak-auth';
import { menuRoutes } from 'constants/routes';
import { ECatalogSlug } from 'features/Catalog/types';
import { IProfileStore } from 'stores';
import { getLink } from 'utils';
import { IProfile } from 'types';
import { IS_SAAS } from 'constants/common';

export const useGetConfigForLayout = ({
  user,
  backlink,
  resetRightsForPage,
  setReleaseModalVisible,
}: Pick<IProfileStore, 'user' | 'backlink' | 'resetRightsForPage'> & {
  setReleaseModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [selected, setSelected] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    resetRightsForPage();

    const path = location.pathname.split('/')[1];
    if (path) {
      const newSelected = `/${path}`;
      switch (newSelected) {
        case menuRoutes.PROPOSALS:
          setSelected(menuRoutes.PROPOSALS);
          break;
        case menuRoutes.CATALOG:
          setSelected(menuRoutes.CATALOG);
          break;
        case menuRoutes.TESTING:
          setSelected(menuRoutes.TESTING);
          break;
        default:
          setSelected(newSelected);
          break;
      }
    }
  }, [location.pathname, resetRightsForPage]);

  const getLinkWithLimit = (key: string, user: IProfile | null) => {
    const limit = user?.metadata?.pageLimit?.[key] || 50;

    return String(getLink(key, user, limit));
  };

  const menuItems = useMemo(() => {
    let result: ISubMenuItem[] = [];
    if (backlink) {
      result.push({
        key: 'backlink',
        title: 'Вернуться назад',
        link: backlink,
        icon: <BackSquareOutline />,
        external: true,
      });
    }

    result = result.concat(
      ...(IS_SAAS
        ? []
        : [
            {
              key: menuRoutes.PROPOSALS,
              title: 'Заявки НСИ',
              link: getLinkWithLimit(menuRoutes.PROPOSALS, user),
              icon: <ArchiveBookOutline />,
            },
            {
              key: menuRoutes.DESCRIPTION_OF_GROUP_PAGE,
              title: 'Описание групп работ',
              link: getLinkWithLimit(`${menuRoutes.DESCRIPTION_OF_GROUP_PAGE}`, user),
              icon: <Grid6Outline />,
            },
          ]),
      {
        key: menuRoutes.CATALOG,
        title: 'Справочники',
        link: getLinkWithLimit(`${menuRoutes.CATALOG}/${ECatalogSlug.KINDS}`, user),
        icon: <BookOutline />,
      },
    );

    if (user?.isSuperuser && !IS_SAAS) {
      result.push({
        key: menuRoutes.TESTING,
        title: 'Тестирование',
        link: menuRoutes.TESTING,
        icon: <ShieldSearchOutline />,
      });
    }

    return result;
  }, [backlink, user]);

  const config: ILayoutProps = useMemo(
    () => ({
      header: {
        links: [
          ...(user?.isSuperuser
            ? [
                { link: '/admin/', text: 'Админка', icon: <AndroidOutlined /> },
                {
                  link: '/explorer/',
                  text: 'SQL Explorer',
                  icon: <DatabaseOutlined />,
                },
              ]
            : []),
          ...(!IS_SAAS
            ? [
                {
                  link: '#release',
                  text: 'Обновления',
                  icon: <DriverRefreshOutline />,
                  onClick: () => setReleaseModalVisible(true),
                },
              ]
            : []),
        ],
        profile: user!,
        logoutUrl: IS_SAAS ? keycloakAuthService.getLogoutUrl() : undefined,
        hideApplications: IS_SAAS,
        hideNotifications: IS_SAAS,
        hideMenu: IS_SAAS,
      },
      sider: {
        menu: [{ title: '', key: '1', items: menuItems }],
        menuProps: {
          selectedKeys: [selected],
        },
      },
    }),
    [user, menuItems, selected, setReleaseModalVisible],
  );

  return config;
};
