import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { Loader } from '@devdept/samolet-ui';
import { BACKLINK_PARAM_NAME, IS_SAAS } from 'constants/common';
import { appendFeedbackScript } from 'utils';
import { useProfileStore } from 'stores';
import { useQuery } from 'hooks';

const Profile: React.FC = observer(({ children }) => {
  const profileStore = useProfileStore();
  const { replaceQuery } = useQuery();

  const username = profileStore.user?.username;

  useEffect(() => {
    profileStore.getBacklink();
    profileStore.fetchProfile();
  }, [profileStore]);

  useEffect(() => {
    if (!username) return;
    if (!IS_SAAS) {
      appendFeedbackScript(username);
    }
  }, [username]);

  useEffect(() => {
    if (profileStore.backlink) {
      replaceQuery({ [BACKLINK_PARAM_NAME]: undefined });
    }
  }, [profileStore.backlink]);

  if (profileStore.loading) {
    return <Loader type={'start'} />;
  }

  if (!profileStore.user?.loggedIn) return null;

  return <>{children}</>;
});

export default Profile;
