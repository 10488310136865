import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { inject, injectable } from 'inversify';
import { ParsedQuery } from 'query-string';
import axios, { AxiosError } from 'axios';
import { captureException } from '@sentry/react';
import { API_URLS } from 'constants/apiUrls';
import { DEFAULT_LIMIT_PAGE } from 'constants/common';

import { IListResponse, IRriProposalsListItem } from 'types';
import * as stores from 'stores';
import { get } from 'api/request';

export interface IProposalsListStore {
  tableData: IListResponse<IRriProposalsListItem>;
  loading: boolean;
  getProposalsList: (params: Record<string, any>) => Promise<void>;
  cachedQuery: ParsedQuery<string | boolean>;
  resetProposalsList: () => void;
}

const INITIAL_TABLE_DATA: IListResponse<IRriProposalsListItem> = {
  count: 0,
  results: [],
  next: null,
  previous: null,
};

@injectable()
export class ProposalsListStore implements IProposalsListStore {
  @observable
  tableData = INITIAL_TABLE_DATA;

  @observable
  loading = false;

  @inject(stores.STORES.Router)
  private _router: stores.IRouterStore;

  @inject(stores.SERVICES.localStorage)
  private _localStorage: Storage;

  constructor() {
    makeObservable(this);
  }

  @computed
  get cachedQuery() {
    const query = this._localStorage.getItem(this.constructor.name) ?? '';

    return query ? JSON.parse(query) : DEFAULT_LIMIT_PAGE;
  }

  @action.bound
  async getProposalsList(params: Record<string, any>) {
    try {
      this.loading = true;

      const response: IListResponse<IRriProposalsListItem> = await get(
        API_URLS.proposals,
        params,
        {
          errorMessage: 'Ошибка получения списка заявок НСИ',
        },
      );

      runInAction(() => {
        this.tableData = response;

        this._localStorage.setItem(
          this.constructor.name,
          JSON.stringify(this._router.query),
        );
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError<{ [errorType: string]: string[] | string }>;
        if (error.response) {
          const { status } = error.response;

          if ([404].includes(status)) {
            const response = await get(API_URLS.proposals, { ...params, page: 1 });

            runInAction(() => {
              this.tableData = response;

              this._localStorage.setItem(
                this.constructor.name,
                JSON.stringify(this._router.query),
              );
            });
          } else {
            captureException(e);

            runInAction(() => {
              this.tableData = INITIAL_TABLE_DATA;
              this._localStorage.removeItem(this.constructor.name);
              console.error('[getProposalsList] - ', e);
            });
          }
        }
      }
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action.bound
  resetProposalsList() {
    this.tableData = INITIAL_TABLE_DATA;
  }
}
