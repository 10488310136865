import { useMemo } from 'react';
import { useQuery } from 'hooks';

interface IRequestParams {
  search?: string;
  page?: number;
  limit?: number;
  name?: string;
  classifier?: string;
  group?: string;
  structure_type?: string;
  lot?: string;
  kind?: string;
  unit?: string;
  id?: string;
  cluster?: number;
  businessUnit?: number;
  region?: number;
  businessStatus?: string;
  stage?: string;
  construction?: boolean;
}

export const useRequestParams = () => {
  const { query, pushQuery } = useQuery();

  return useMemo<IRequestParams>(() => {
    if (!query.show_mine) {
      delete query.show_mine;
    }
    if (!query.show_mine_in_work) {
      delete query.show_mine_in_work;
    }

    pushQuery(query);

    return Object.keys(query).length > 0
      ? {
          ...query,
          search: query.search ? String(query.search) : '',
          limit: query.limit ? Number(query.limit) : 50,
          page: query.page ? Number(query.page) : 1,
        }
      : {};
  }, [pushQuery, query]);
};
