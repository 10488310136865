import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import React, { Suspense } from 'react';
import ruRU from 'antd/es/locale/ru_RU';

import { Loader } from '@devdept/samolet-ui';
import { TendUI, useTheme } from '@10d/tend-ui/theme';
import { Dialog, Toast } from '@10d/tend-ui/primitives';
import { withProfiler } from '@sentry/react';
import axios from 'axios';
import { setAxiosKeycloakAuthInterceptor } from 'samolet-keycloak-auth';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'theme/GlobalStyle';
import { Provider as StoreProvider } from 'stores';
import Container from 'components/Container';
import Profile from 'features/Profile';
import Routes from 'pages/Routes';
import { KeycloakAuthProvider } from 'components/KeycloakAuthProvider/KeycloakAuthProvider';
import { initSentry } from '../config/sentryConfig';
import storeContainer from './inversify.config';

// TODO Объединить init
Toast.init();
TendUI.init();

const App: React.FC = () => {
  const theme = useTheme();

  return (
    <KeycloakAuthProvider>
      <Suspense fallback={<Loader />}>
        <TendUI>
          <Toast.Styles />
          <ThemeProvider theme={theme}>
            <Dialog.Styles />
            <StoreProvider value={storeContainer}>
              <ConfigProvider locale={ruRU}>
                <BrowserRouter>
                  <GlobalStyle />
                  <Profile>
                    <Container>
                      <Routes />
                    </Container>
                  </Profile>
                </BrowserRouter>
              </ConfigProvider>
            </StoreProvider>
          </ThemeProvider>
        </TendUI>
      </Suspense>
    </KeycloakAuthProvider>
  );
};

export default withProfiler(App);
