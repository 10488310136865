export function appendFeedbackScript(username: string) {
  const isStage = ['-stage', 'localhost', '127.0.0.1'].some(e =>
    window.location.host.includes(e),
  );
  const sfbScriptUrl = `https://sfb${
    isStage ? '-stage' : ''
  }.samoletgroup.ru/v1/static/sfb.js`;
  const projectName = 'rri';
  const LOAD_DELAY = 4000;

  const sfbScript = document.createElement('script');
  sfbScript.type = 'text/javascript';
  sfbScript.src = sfbScriptUrl;
  sfbScript.onload = () => {
    (window as any).sfb && (window as any).sfb.run(projectName, username);
  };

  setTimeout(() => {
    document.body.appendChild(sfbScript);
  }, LOAD_DELAY);
}
