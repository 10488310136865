import { ParsedQuery } from 'query-string';
import { useCallback } from 'react';
import _ from 'lodash';

import { useProfileStore } from 'stores';

interface IUseFiltersSaver {
  route: string;
}

export const useFiltersSaver = ({ route }: IUseFiltersSaver) => {
  const profileStore = useProfileStore();

  const defaultFilters = profileStore.user?.metadata?.filters
    ? profileStore.user.metadata.filters[route]
    : {};

  const saveFilters = useCallback(
    (selectedFilters: ParsedQuery) => {
      const oldFiltersReset = _.mapValues(defaultFilters, () => undefined);

      const paramsFilter = _.omit(selectedFilters, ['limit', 'page', 'ordering']);
      if (!_.isEqual(defaultFilters, paramsFilter)) {
        profileStore.changeMetadata({
          filters: {
            [route]: { ...oldFiltersReset, ...paramsFilter },
          },
        });
      }
    },
    [defaultFilters, route],
  );

  return { saveFilters };
};
