import { useEffect, useState } from 'react';

import { useRequestParams } from 'hooks';

export const useActiveFilters = () => {
  const params = useRequestParams();

  const [isActiveNameFilter, setIsActiveNameFilter] = useState(false);
  const [isActiveClassifierFilter, setIsActiveClassifierFilter] = useState(false);
  const [isActiveGroupFilter, setIsActiveGroupFilter] = useState(false);
  const [isActiveKindFilter, setIsActiveKindFilter] = useState(false);
  const [isActiveStructureTypeFilter, setIsActiveStructureTypeFilter] = useState(false);
  const [isActiveLotFilter, setIsActiveLotFilter] = useState(false);
  const [isActiveUnitFilter, setIsActiveUnitFilter] = useState(false);
  const [isActiveStructureNameFilter, setIsActiveStructureNameFilter] = useState(false);
  const [isActiveClusterFilter, setIsActiveClusterFilter] = useState(false);
  const [isActiveBusinessUnitFilter, setIsActiveBusinessUnitFilter] = useState(false);
  const [isActiveRegionFilter, setIsActiveRegionFilter] = useState(false);
  const [isActiveBusinessStatusFilter, setIsActiveBusinessStatusFilter] = useState(false);
  const [isActiveStageFilter, setIsActiveStageFilter] = useState(false);
  const [isActiveConstructionFilter, setIsActiveConstructionFilter] = useState(false);

  useEffect(() => {
    if (params.name) {
      setIsActiveNameFilter(true);
    } else {
      setIsActiveNameFilter(false);
    }
    if (params.classifier) {
      setIsActiveClassifierFilter(true);
    } else {
      setIsActiveClassifierFilter(false);
    }
    if (params.group) {
      setIsActiveGroupFilter(true);
    } else {
      setIsActiveGroupFilter(false);
    }
    if (params.kind) {
      setIsActiveKindFilter(true);
    } else {
      setIsActiveKindFilter(false);
    }
    if (params.structure_type) {
      setIsActiveStructureTypeFilter(true);
    } else {
      setIsActiveStructureTypeFilter(false);
    }
    if (params.lot) {
      setIsActiveLotFilter(true);
    } else {
      setIsActiveLotFilter(false);
    }
    if (params.unit) {
      setIsActiveUnitFilter(true);
    } else {
      setIsActiveUnitFilter(false);
    }
    if (params.id) {
      setIsActiveStructureNameFilter(true);
    } else {
      setIsActiveStructureNameFilter(false);
    }
    if (params.cluster) {
      setIsActiveClusterFilter(true);
    } else {
      setIsActiveClusterFilter(false);
    }
    if (params.businessUnit) {
      setIsActiveBusinessUnitFilter(true);
    } else {
      setIsActiveBusinessUnitFilter(false);
    }
    if (params.region) {
      setIsActiveRegionFilter(true);
    } else {
      setIsActiveRegionFilter(false);
    }
    if (params.businessStatus) {
      setIsActiveBusinessStatusFilter(true);
    } else {
      setIsActiveBusinessStatusFilter(false);
    }
    if (params.stage) {
      setIsActiveStageFilter(true);
    } else {
      setIsActiveStageFilter(false);
    }
    if (params.construction) {
      setIsActiveConstructionFilter(true);
    } else {
      setIsActiveConstructionFilter(false);
    }
  }, [params]);

  return {
    isActiveNameFilter: isActiveNameFilter,
    isActiveClassifierFilter: isActiveClassifierFilter,
    isActiveGroupFilter: isActiveGroupFilter,
    isActiveKindFilter: isActiveKindFilter,
    isActiveStructureTypeFilter: isActiveStructureTypeFilter,
    isActiveLotFilter: isActiveLotFilter,
    isActiveUnitFilter: isActiveUnitFilter,
    isActiveStructureNameFilter: isActiveStructureNameFilter,
    isActiveClusterFilter: isActiveClusterFilter,
    isActiveBusinessUnitFilter: isActiveBusinessUnitFilter,
    isActiveRegionFilter: isActiveRegionFilter,
    isActiveBusinessStatusFilter: isActiveBusinessStatusFilter,
    isActiveStageFilter: isActiveStageFilter,
    isActiveConstructionFilter: isActiveConstructionFilter,
  };
};
