export const VALIDATE_MESSAGE_REQUIRED = 'Обязательно к заполнению';

export const SHORT_STRING_LENGTH = 150;

export const LONG_STRING_LENGTH = 1000;

export const DATE_FORMAT = 'DD-MM-YYYY';

export const DATE_FORMAT_COMMENTS = 'DD.MM.YYYY';

export const DATE_FORMAT_HISTORY = 'DD.MM.YYYY HH:mm';

export const DATE_FORMAT_WITH_TIME = 'DD.MM.YYYY HH:mm:ss';

export const BACKLINK_PARAM_NAME = 'backlink';

export const SUCCESS_COPY_ONECID_MESSAGE = 'Номер заявки скопирован в буфер обмена';

export const DEFAULT_LIMIT_PAGE = { limit: 50, page: 1 };

export const DEFAULT_NO_RESTORE_PARAM_NAME = 'noRestore';

export const ERROR_VALIDATION_MESSAGE = 'Это обязательное поле';

export const MAX_FILES_SIZE_ERROR_TEXT =
  'Произошла ошибка при загрузке файла. Размер файла превышает допустимый лимит';
export const MAX_FILES_SIZE_ERROR_TEXT_CHAT =
  'Превышена максимальная сумма размера файлов - 100Мб';

export const UPLOAD_TITLE = 'Загрузите или перетащите файл сюда';
export const UPLOADS_TITLE = 'Вложения';

export const DRAGGER_HINT = {
  countText: 'не более 10 файлов любого формата',
  sizeText: 'размер одного файла — 100 Mb',
  maxSize: 'Максимальный размер',
  noMore: 'Не более',
  files: 'файлов',
};

export const MODAL_TYPES = {
  goBack: 'goBack',
  emptyString: 'emptyString',
  emptyMapping: 'emptyMapping',
  isSameText: 'isSameText',
  correctValues: 'correctValues',
  updatedDoubleMapping: 'updatedDoubleMapping',
  isAlreadyExist: 'isAlreadyExist',
  emptyGroupMapping: 'emptyGroupMapping',
  hasNoChanged: 'hasNoChanged',
  switchAlert: 'switchAlert',
  emptyDeleteMapping: 'emptyDeleteMapping',
  isDoubledFields: 'isDoubledFields',
  emptySelected: 'emptySelected',
};

export const MAX_FILE_SIZE = 104857600;

export const TOAST_MESSAGES = {
  errorEmptyClassifierMessage:
    'Для данного материала не указан вид продукции, пожалуйста, удалите строку или выберите другой материал/вид продукции',
  successProjectMessage: 'Заявка на добавление проекта в справочник отправлена',
  successSpecializationMessage: 'Заявка на добавление проекта в справочник отправлена',
  successMaterialMessage: 'Заявка на добавление материала в справочник отправлена',
  successCopyMaterialMessage:
    'Заявка на добавление копирование материала в справочник отправлена',
  successDeleteMatrixMessage: 'Заявка на удаление соответствия отправлена',
  commonError: 'Проверьте правильность заполнения параметров заявки',
  proposalViewMappingError: 'Заполните соответствия корректно',
  copyMessage: 'Текст прежнего состава работ скопирован в буфер обмена',
  successChangeKindMessage: 'Заявка на изменение состава отправлена',
  successCopyKindMessage: 'Заявка на копирование соответствий ГР отправлена',
  successCopyMatrixMessage: 'Заявка на копирование соответствий ВР отправлена',
  successChangeStatusKindMessage: 'Заявка на смену статуса работы отправлена',
  successMatrixProposalMessage: 'Заявка на смену статуса работы отправлена',
  chooseMatrixError:
    'Для данного материала не указан вид продукции, пожалуйста, удалите строку или выберите другой материал/вид продукции',
  copyNumberProposal: 'Номер заявки скопирован в буфер обмена',
  copy1c: 'Ссылка на проект в 1С скопирована в буфер обмена',
  assignedMessage: 'Ссылка на проект в 1С скопирована в буфер обмена',
  maxFilesSizeError:
    'Произошла ошибка при загрузке файла. Размер файла превышает допустимый лимит',
};

export const BUTTONS_TITLES = {
  back: 'Назад',
  next: 'Далее',
  confirm: 'Отправить на согласование',
};

export const DOWNLOAD_EXCEL_TEXT = 'Скачать Excel';

export const SAAS = 'SAAS';

export const IS_SAAS = process.env.REACT_APP_ENVIRON === SAAS;
