import { Route, RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { paths } from 'constants/routes';

import { useProfileStore } from 'stores';
import Page404 from 'pages/Page404';

interface IProtectedRoute extends RouteProps {
  component: React.ComponentType;
  path: string;
}

const ProtectedRoute: React.FC<IProtectedRoute> = observer(
  ({ component: Component, ...rest }) => {
    const { user, isNotEnoughRightsForPage } = useProfileStore();

    const onlySuperusersPaths: { [key: string]: boolean } = useMemo(
      () => ({
        [paths.TESTING]: !user?.isSuperuser,
      }),
      [user],
    );

    const renderRoute = (args: any) => {
      if (!isNotEnoughRightsForPage && !onlySuperusersPaths[rest.path]) {
        return <Component {...args} />;
      }

      return <Page404 />;
    };

    return <Route {...rest} render={renderRoute} />;
  },
);

export default ProtectedRoute;
