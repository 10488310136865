import { computed, observable } from 'mobx';
import { inject, injectable } from 'inversify';
import qs, { ParsedQuery } from 'query-string';

import { SERVICES } from 'stores/bindings';

export interface IRouterStore {
  location: Location;
  history: History;
  query: ParsedQuery<string | boolean>;
}

@injectable()
export class RouterStore implements IRouterStore {
  @inject(SERVICES.location)
  @observable.deep
  location: Location;

  @inject(SERVICES.history)
  history: History;

  @computed
  get query() {
    return qs.parse(this.location.search, { parseBooleans: true });
  }
}
