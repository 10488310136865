import React from 'react';
import { SOMETHING_WRONG } from './constants';
interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component {
  state: IState = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <h2>{SOMETHING_WRONG}</h2>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
