export enum EChatMessageType {
  DECLINED = 'DECLINE',
  CLARIFICATION = 'CLARIFICATION',
  NEED_ANSWER = 'NEED_ANSWER',
  DEFAULT = 'DEFAULT',
}

export interface IChatMessage {
  id: number;
  created: string;
  comment: string;
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    department?: string;
    position?: string;
  };
  replyTo?: Omit<IChatMessage, 'replyTo' | 'attachments' | 'my'>;
  messageType: EChatMessageType;
  my: boolean;
  attachments?: { fileName: string; file: string }[];
}

export interface IChatPostMessage {
  proposalId: number;
  replyTo?: number;
  comment: string;
  messageType?: EChatMessageType;
  attachments?: (number | undefined)[];
}

export enum EScrollDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}
