import 'moment/locale/ru';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

import 'reflect-metadata';

import 'antd/dist/antd.css';

import '@devdept/samolet-ui/lib/theme/global.css';

import { setAxiosKeycloakAuthInterceptor } from 'samolet-keycloak-auth';
import axios from 'axios';
import App from 'App';
import { IS_SAAS } from 'constants/common';

setAxiosKeycloakAuthInterceptor(axios);

moment.defaultFormat = 'DD.MM.YYYY';
moment.locale('ru');

ReactDOM.render(<App />, document.getElementById('root'));

module?.hot?.accept();
