import { UploadFile as IUploadFile } from 'antd/es/upload/interface';
import { ERriType } from 'types';

export enum ERriProposalStatus {
  NEW = 'NEW',
  IN_WORK = 'IN_WORK',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  CLARIFICATION = 'CLARIFICATION',
  CLARIFICATION_METODOLOGIST = 'CLARIFICATION_METODOLOGIST',
}

export enum ERoles {
  METHODOLOGIST = 'METHODOLOGIST',
  APPRAISER = 'APPRAISER',
  CURATOR = 'CURATOR',
  ECONOMIST = 'ECONOMIST',
}

export const enum EStaticFields {
  NAME = 'name',
  FULL_NAME = 'fullName',
  UNIT = 'unit',
  KIND_FOLDER = 'kindFolder',
  CLASSIFIER = 'classifier',
}

export type TStaticFields =
  | 'name'
  | 'fullName'
  | 'unit'
  | 'kindFolder'
  | 'classifier'
  | 'parent'
  | 'lot'
  | 'status'
  | 'group'
  | 'structureType'
  | 'vendor_code'
  | 'fullNameOld'
  | 'codifier'
  | 'category'
  | 'construction'
  | 'business_unit'
  | 'cluster'
  | 'region'
  | 'business_status'
  | 'business_stage'
  | 'project_manager'
  | 'description'
  | 'project'
  | 'status';

export interface IRequestBodyItem {
  field?: TStaticFields;
  label: string;
  value: string;
  boolValue?: boolean;
  guid?: string;
  id: number | string;
  oldValue?: string | number;
}

export interface IKindMatricesItem {
  name: string;
  id: number | null;
  status?: {
    id?: number;
    name?: string;
  };
}
export interface IKindMatricesObject {
  group: IKindMatricesItem;
  structure: IKindMatricesItem;
  structureType: IKindMatricesItem;
  added?: boolean;
  deleted?: boolean;
}

export interface IGroupMatricesObject {
  kind: IKindMatricesItem;
  structureType: IKindMatricesItem;
  lot: IKindMatricesItem;
  added?: boolean;
  deleted?: boolean;
}

export interface ILotMatricesObject {
  kind: IKindMatricesItem;
  structureType: IKindMatricesItem;
  added?: boolean;
  deleted?: boolean;
}

export interface IMaterialMatricesItem {
  name: string;
  id: number | null;
}

export interface IMaterialMatricesObject {
  material: IMaterialMatricesItem;
  classifier: IMaterialMatricesItem;
  added?: boolean;
  deleted?: boolean;
}

interface IAdditionalData {
  id: number;
  field: string;
  label: string;
  value: string;
}

export interface IProposalDetail {
  id: number;
  created: string;
  canTakeIntoWork: boolean;
  canChangeExecutor: boolean;
  executor: string;
  canAddComment: boolean;
  additionalData?: IAdditionalData[];
  canViewCommentOfReturning: boolean;
  elementType: {
    id: ERriType;
    name: string;
  };
  status: {
    id: ERriProposalStatus;
    name: string;
  };
  stage: {
    id: string;
    name: string;
  };
  argument: string;
  author: {
    id: number;
    email: string;
    fullName: string;
  };
  kindMatrices: IKindMatricesObject[];
  groupMatrices: IGroupMatricesObject[];
  lotMatrices: IGroupMatricesObject[];
  materialMatrices: IMaterialMatricesObject[];
  comment: string;
  requestBody: IRequestBodyItem[];
  onecLink: string;
  onecId: string;
  history?: IProposalHistoryItem[];
  catalogElementName?: string | null;
  permissions?: string[];
  sourceProject?: {
    id: string;
    name: string;
  };
}

export interface IRriProposalsListItem {
  id: number;
  argument: string;
  author: string;
  created: string;
  executor: string;
  canTakeIntoWork: boolean;
  elementType: {
    id: ERriType;
    name: string;
  };
  status: {
    id: ERriProposalStatus;
    name: string;
  };
  onecId: string;
  name?: string;
  requestBody?: IRequestBodyItem[];
  stage: { id: string; name: string };
}

export interface IProposalHistoryItem {
  name: string;
  active: boolean;
  basicState: ERriProposalStatus;
  comment?: string;
  assignee: string | null;
  executor: string | null;
}

export interface IPostChangeState {
  id: string | number;
  state: Exclude<ERriProposalStatus, ERriProposalStatus.NEW | ERriProposalStatus.IN_WORK>;
  comment?: string;
}
export interface IExecutorChange {
  id: string | number;
  newExecutor: number;
  comment?: string;
}

export type FileListItem = IUploadFile & { id?: number };
