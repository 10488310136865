import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { inject, injectable } from 'inversify';
import _ from 'lodash';
import axios, { AxiosError } from 'axios';
import { Toast } from '@10d/tend-ui/primitives';
import { captureException } from '@sentry/react';
import { API_URLS } from 'constants/apiUrls';
import { PERMISSIONS } from 'constants/permissions';

import * as ExtChat from 'features/ExtChat';
import * as stores from 'stores';
import {
  ERriProposalStatus,
  IListResponse,
  IPostChangeState,
  IProposalDetail,
} from 'types';
import { get, post } from 'api/request';
import { trailingSlash } from 'utils';
import { IExecutorChange } from 'types/proposals';

const permissionsProposalView = [
  'canProposalEdit',
  'canProposalReject',
  'canProposalClarify',
  'canProposalComplete',
  'canSeeClarifyAlert',
  'isAppraiser',
  'canEditProposalFields',
  'canEditMatrixLotProposalFields',
  'canEditWorkMapping',
  'canEditChangeKind',
  'canEditMaterialMapping',
  'canEditCopyGroupMapping',
  'canEditEventProposalFields',
  'canSeeClarifyButtonForMetodologist',
  'canEditProject',
  'canEditChangeProject',
] as const;

export type TPermissionsProposalView = (typeof permissionsProposalView)[number];

export interface IProposalViewStore {
  proposal: IProposalDetail | null;
  messages: ExtChat.IChatMessage[];
  loadingProposal: boolean;
  loadingMessages: boolean;
  loadingChangeState: boolean;
  permissions: { [key in TPermissionsProposalView]: boolean };
  getProposal: (id: string) => Promise<void>;
  getMessages: (
    id: string | number,
    options?: {
      isNext?: boolean;
      isUpdate?: boolean;
    },
  ) => Promise<void>;
  postMessage: (data: ExtChat.IChatPostMessage, id: string) => Promise<void>;
  changeState: (data: {
    payload: IPostChangeState;
    onSuccess?: () => void;
  }) => Promise<void>;
  changeStateFroMetodologist: (data: {
    payload: IPostChangeState;
    onSuccess?: () => void;
  }) => Promise<void>;
  changeExecutor: (data: {
    payload: IExecutorChange;
    onSuccess?: () => void;
  }) => Promise<void>;
  resetProposal: () => void;
}

@injectable()
export class ProposalViewStore implements IProposalViewStore {
  @observable
  proposal: IProposalViewStore['proposal'] = null;

  @observable
  loadingProposal = false;

  @observable
  loadingMessages = false;

  @observable
  loadingChangeState = false;

  @observable
  private _messages: {
    [id: number]: ExtChat.IChatMessage;
  } = {};

  private _nextLink: string | null = null;

  @inject(stores.STORES.Profile)
  private _profile: stores.IProfileStore;

  constructor() {
    makeObservable(this);
  }

  @computed
  get messages(): IProposalViewStore['messages'] {
    return Object.values(this._messages);
  }

  @computed
  get permissions(): IProposalViewStore['permissions'] {
    const permissionsNorm = _.keyBy(this.proposal?.permissions, item => item);

    const result = permissionsProposalView.reduce((acc, permission) => {
      acc[permission] = !!permissionsNorm[PERMISSIONS[permission]];

      return acc;
    }, {} as IProposalViewStore['permissions']);

    // TODO: переделать на return result; при возможности редактировать все 4 типа заявок
    return {
      ...result,
      canProposalEdit: false,
      // result.canProposalEdit &&
      // [ERriType.KIND, ERriType.MATERIAL].includes(this.proposal!.elementType.id),
    };
  }

  @action.bound
  async getProposal(id: string) {
    try {
      this.loadingProposal = true;
      this._profile.isNotEnoughRightsForPage = false;
      const response: IProposalDetail = await get(
        `${trailingSlash(API_URLS.proposals)}${id}/`,
        {},
        {
          errorMessage: 'Ошибка получения заявки НСИ',
        },
      );
      runInAction(() => {
        this.proposal = response;
      });
    } catch (e) {
      captureException(e);

      console.error('[getProposal] - ', e);
      runInAction(() => {
        this.proposal = null;
        if (axios.isAxiosError(e)) {
          const error = e as AxiosError;
          this._profile.isNotEnoughRightsForPage = error.response?.status === 404;
        }
      });
    } finally {
      runInAction(() => {
        this.loadingProposal = false;
      });
    }
  }

  @action.bound
  async getMessages(
    id: string | number,
    options?: {
      isNext?: boolean;
      isUpdate?: boolean;
    },
  ) {
    try {
      if ((options?.isNext && this._nextLink) || !options?.isNext) {
        this.loadingMessages = true;
        const response: IListResponse<ExtChat.IChatMessage> = await get(
          options?.isNext
            ? (this._nextLink as string)
            : `${trailingSlash(API_URLS.proposalComments)}${id}/?limit=20`,
          {},
          {
            errorMessage: 'Ошибка получения списка сообщений',
            disableSuccessMessages: true,
          },
        );
        runInAction(() => {
          if (!options?.isUpdate) {
            this._nextLink = response.next;
          }

          this._messages = response.results.reduce((acc, item) => {
            acc[item.id] = item;

            return acc;
          }, this._messages);
        });
      }
    } catch (error) {
      captureException(error);

      console.error(`[getMessages] - ${error}`);
    } finally {
      runInAction(() => {
        this.loadingMessages = false;
      });
    }
  }

  @action.bound
  async postMessage(data: ExtChat.IChatPostMessage, id: string) {
    try {
      this.loadingMessages = true;
      await post(API_URLS.proposalComments, data, {
        errorMessage: 'Ошибка отправки сообщения',
        disableSuccessMessages: true,
      });
      await this.getMessages(data.proposalId, { isUpdate: true });
      await this.getProposal(id);
    } catch (error) {
      captureException(error);

      console.error(`[postMessages] - ${error}`);
    } finally {
      runInAction(() => {
        this.loadingMessages = false;
      });
    }
  }

  @action.bound
  async changeState({
    payload: { id, ...payload },
    onSuccess,
  }: {
    payload: IPostChangeState;
    onSuccess?: () => void;
  }) {
    try {
      this.loadingChangeState = true;

      let successMessage;
      switch (payload.state) {
        case ERriProposalStatus.CLARIFICATION:
          successMessage = 'Заявка отравлена на уточнение';
          break;
        case ERriProposalStatus.REJECTED:
          successMessage = 'Заявка отклонена';
          break;
        case ERriProposalStatus.COMPLETED:
          successMessage = 'Заявка согласована';
          break;
      }

      const response: IProposalDetail = await post(API_URLS.changeState(id), payload, {
        errorMessage: 'Ошибка изменения статуса',
        successMessage,
      });
      runInAction(() => {
        this.proposal = response;
        onSuccess && onSuccess();
      });
    } catch (err) {
      captureException(err);

      const error = err as AxiosError;
      if (error.response) {
        const { status, data } = error.response;
        const errorMessage = Object.values(data);

        if (status === 400) {
          errorMessage.forEach((elem: any) =>
            typeof elem[0] === 'string'
              ? Toast.error({ message: `${elem}`, duration: 5 })
              : elem.forEach((item: any) =>
                  Object.values(item).forEach(value =>
                    Toast.error({ message: `${value}`, duration: 5 }),
                  ),
                ),
          );
        }
      }
      console.error(`[changeState] - ${error}`);
    } finally {
      runInAction(() => {
        this.loadingChangeState = false;
      });
    }
  }

  @action.bound
  async changeStateFroMetodologist({
    payload: { id, ...payload },
    onSuccess,
  }: {
    payload: IPostChangeState;
    onSuccess?: () => void;
  }) {
    try {
      this.loadingChangeState = true;

      let successMessage;
      switch (payload.state) {
        case ERriProposalStatus.CLARIFICATION:
          successMessage = 'Заявка отравлена на уточнение';
          break;
        case ERriProposalStatus.REJECTED:
          successMessage = 'Заявка отклонена';
          break;
        case ERriProposalStatus.COMPLETED:
          successMessage = 'Заявка согласована';
          break;
      }

      const response: IProposalDetail = await post(
        API_URLS.changeStateFroMetodologist(id),
        payload,
        {
          errorMessage: 'Ошибка изменения статуса',
          successMessage,
        },
      );
      runInAction(() => {
        this.proposal = response;
        onSuccess && onSuccess();
      });
    } catch (error) {
      captureException(error);

      console.error(`[changeState] - ${error}`);
    } finally {
      runInAction(() => {
        this.loadingChangeState = false;
      });
    }
  }

  @action.bound
  async changeExecutor({
    payload: { id, ...payload },
    onSuccess,
  }: {
    payload: IExecutorChange;
    onSuccess?: () => void;
  }) {
    try {
      this.loadingChangeState = true;

      const response: IProposalDetail = await post(API_URLS.changeExecutor(id), payload, {
        errorMessage: 'Ошибка назначения исполнителя',
      });
      runInAction(() => {
        this.proposal = response;
        onSuccess && onSuccess();
      });
    } catch (error) {
      captureException(error);

      console.error(`[changeExecutor] - ${error}`);
    } finally {
      runInAction(() => {
        this.loadingChangeState = false;
      });
    }
  }

  @action.bound
  resetProposal() {
    this.proposal = null;
    this._messages = {};
    this._nextLink = null;
  }
}
