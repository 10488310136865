import { action, makeObservable, observable, runInAction } from 'mobx';
import { injectable } from 'inversify';
import { captureException } from '@sentry/react';
import axios, { AxiosError } from 'axios';
import { API_URLS } from 'constants/apiUrls';

import { ECatalogSlug } from 'features/Catalog/types';
import { IListResponse, TRriCatalogItem } from 'types';
import { get } from 'api/request';

export interface ICatalogStore {
  tableData: IListResponse<TRriCatalogItem>;
  loading: boolean;
  getCatalog: (slug: ECatalogSlug, params: Record<string, any>) => Promise<void>;
  resetCatalog: () => void;
}

const INITIAL_TABLE_DATA: IListResponse<TRriCatalogItem> = {
  count: 0,
  results: [],
  next: null,
  previous: null,
};

@injectable()
export class CatalogStore implements ICatalogStore {
  @observable
  tableData = INITIAL_TABLE_DATA;

  @observable
  loading = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  async getCatalog(slug: ECatalogSlug, params: Record<string, any>) {
    try {
      this.loading = true;
      // { ...params, page: 1 },

      const response: IListResponse<TRriCatalogItem> = await get(
        API_URLS.catalog[slug],
        params,
        {
          errorMessage: 'Ошибка получения данных справочника',
        },
      );

      runInAction(() => {
        this.tableData = response;
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error = e as AxiosError<{ [errorType: string]: string[] | string }>;
        if (error.response) {
          const { status } = error.response;

          if ([404].includes(status)) {
            const response = await get(API_URLS.catalog[slug], { ...params, page: 1 });

            runInAction(() => {
              this.tableData = response;
            });
          } else {
            runInAction(() => {
              captureException(e);

              this.tableData = INITIAL_TABLE_DATA;
              console.error('[getCatalog] - ', error);
            });
          }
        }
      }
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action.bound
  resetCatalog() {
    this.tableData = INITIAL_TABLE_DATA;
  }
}
