import { observer } from 'mobx-react-lite';
import React from 'react';

import { Text, Title } from '@10d/tend-ui/typography';

import { useProfileStore } from 'stores';
import { Wrapper } from './styled';

const Page404: React.FC = observer(() => {
  const { user } = useProfileStore();

  const email = 'servicedesk@samoletgroup.ru';
  const phone = user?.isStaff ? '+7-495-660-41-41, вн. 5555' : '+7-495-967-31-41';
  const servicedesk = user?.isStaff ? (
    <Text>
      Портал поддержки:{' '}
      <a
        href='https://hd.samoletgroup.ru/servicedesk/customer/portal/11'
        target='_blank'
        rel='noopener noreferrer'
      >
        https://hd.samoletgroup.ru/servicedesk/customer/portal/11
      </a>
    </Text>
  ) : null;

  return (
    <Wrapper>
      <div className='whale' />
      <Title className='title'>
        {`Недостаточно прав для просмотра страницы.
        Чтобы получить доступ, обратитесь в техподдержку`}
      </Title>
      <div className='contacts'>
        <Text>Телефон: {phone}</Text>
        <Text>
          E-mail:{'\u00a0'}
          <a href={`mailto:${email}`}>{email}</a>
        </Text>
        {servicedesk}
      </div>

      <Text className='schedule'>
        {`График работы: пн-чт с 9:00 до 18:00, пт с 9:00 до 16:45
        (кроме выходных и праздничных дней)`}
      </Text>
    </Wrapper>
  );
});

export default Page404;
