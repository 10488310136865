import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useReleaseModal = () => {
  const [releaseModalVisible, setReleaseModalVisible] = useState(false);
  const [firstStep, setStepState] = useState(true);

  const location = useLocation();
  const { replace } = useHistory();

  useEffect(() => {
    !firstStep && !releaseModalVisible && replace(location.pathname + location.search);
  }, [releaseModalVisible]);

  useEffect(() => {
    setStepState(false);
  }, []);

  return { releaseModalVisible, setReleaseModalVisible };
};
