import styled from 'styled-components';

import { colors } from '@devdept/samolet-ui';

import WhaleSad2 from 'assets/img/whaleSad2.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  & .whale {
    height: 132px;
    width: 108px;
    background-image: url(${WhaleSad2});
    margin-bottom: 42px;
  }

  & .title {
    font-weight: 700;
    text-align: center;
    white-space: pre-line;
    margin-bottom: 32px;
  }

  & .contacts {
    text-align: center;
    margin-bottom: 32px;
  }

  & .schedule {
    text-align: center;
    white-space: pre-line;
    color: ${colors.secondaryText};
    font-size: 12px;
  }
`;
