import { Container } from 'inversify';
import { History, createBrowserHistory } from 'history';

import {
  CatalogStore,
  ICatalogStore,
  IProfileStore,
  IProposalViewStore,
  IProposalsListStore,
  IRouterStore,
  ProfileStore,
  ProposalViewStore,
  ProposalsListStore,
  RouterStore,
  SERVICES,
  STORES,
} from 'stores';

const storeContainer = new Container();

const history = createBrowserHistory();
const { location, localStorage } = window;

storeContainer.bind<IProfileStore>(STORES.Profile).to(ProfileStore).inSingletonScope();
storeContainer
  .bind<IProposalsListStore>(STORES.ProposalsList)
  .to(ProposalsListStore)
  .inSingletonScope();
storeContainer
  .bind<IProposalViewStore>(STORES.ProposalView)
  .to(ProposalViewStore)
  .inSingletonScope();
storeContainer.bind<ICatalogStore>(STORES.Catalog).to(CatalogStore).inSingletonScope();
storeContainer.bind<IRouterStore>(STORES.Router).to(RouterStore).inSingletonScope();

storeContainer.bind<Location>(SERVICES.location).toConstantValue(location);
storeContainer.bind<Storage>(SERVICES.localStorage).toConstantValue(localStorage);
storeContainer.bind<History>(SERVICES.history).toConstantValue(history);

export default storeContainer;
