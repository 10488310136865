import { useMemo } from 'react';
import { useProfileStore } from 'stores';

export const useGetSavedFilters = () => {
  const profileStore = useProfileStore();

  const savedFilters = useMemo(
    () => profileStore.user?.metadata?.filters,
    [profileStore.user],
  );

  return { savedFilters };
};
