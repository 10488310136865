import { getRealm, keycloakAuthService } from 'samolet-keycloak-auth';
import React, { useEffect, useState } from 'react';
import PageLoader from 'components/PageLoader/PageLoader';

export const KeycloakAuthProvider = ({ children }: { children: JSX.Element }) => {
  const [isKCLoading, setIsKCLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const initKeycloak = async () => {
    try {
      setIsKCLoading(true);
      const res = await keycloakAuthService.initKeycloak(
        {
          url:
            process.env.NODE_ENV === 'development'
              ? 'https://keycloak-saas.yc-stage.samolet10d.ru'
              : __KEYCLOAK_INIT_URL__,
          realm: process.env.NODE_ENV === 'development' ? 'alpha' : getRealm(),
          clientId: 'rri-frontend',
        },
        true,
      );
      if (res) setIsAuthorized(true);
    } catch (error) {
      console.warn('[Error keycloak init]:', error);
    } finally {
      setIsKCLoading(false);
    }
  };

  useEffect(() => {
    initKeycloak();
  }, []);

  return <>{isKCLoading && !isAuthorized ? <PageLoader /> : <>{children}</>}</>;
};
