import { createGlobalStyle } from 'styled-components';

import { colors, messageStyle } from '@devdept/samolet-ui';

export const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    scrollbar-color: rgba(0, 0, 0, 0.2) ${colors.grayBlue2};
    scrollbar-width: thin;
    color: ${colors.primaryText};
  }
  a {
    color: ${colors.primary}
  }

  a.ant-btn.onecLink {
    padding: 4px 20px!important;
  }

  .ant-page-header-heading-extra {
    display: flex;
  }

  .ant-typography {
    color: ${colors.primaryText};
  } 
 

  .ant-btn > .ant-btn-loading-icon .anticon {
    animation: none;
  }

  .ant-btn > .ant-btn-loading-icon .anticon > svg {
    animation: loadingCircle 1s linear infinite;
  }

  .ant-select:not(.ant-select-multiple) .ant-select-selection-item {
    background-color: initial !important;
  }


  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-button {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar-track {
    background: ${colors.grayBlue2};
    border: 1px none ${colors.gray10};
    border-radius: 33px;
  }
  ::-webkit-scrollbar-track:hover {
    background: ${colors.gray5};
  }
  ::-webkit-scrollbar-track:active {
    background: ${colors.gray5};
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

 .ant-input-number {
    border-color: ${colors.border};
    color: ${colors.primaryText};
    & input::placeholder {
      color: ${colors.disable}
    }
  }
 

  h3.ant-typography {
    color: ${colors.title}
  }

  main.ant-layout-content{
    overflow: auto;
  }

  & .ant-collapse-header {
    display: flex;

    & .ant-collapse-extra {
      margin-left: auto;
    }
  }

  & .ant-tabs {

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    border-radius: 16px 16px 0 0;
    height: 3px !important;
  }

  .ant-tabs-tab.ant-tabs-tab-disabled {
      color: ${colors.disable};
    }
  }
  
  .ant-tag {
    font-weight: 500;
  }
  ${messageStyle}

 // temporal fix for SAAS RRI - need to change Samolet-UI styles for Avatar
  header > div:nth-child(2) > div:nth-child(1) {
  margin-left: auto;
  }
  
`;
