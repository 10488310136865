import * as R from 'ramda';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { inject, injectable } from 'inversify';
import _ from 'lodash';
import { captureException } from '@sentry/react';
import { PERMISSIONS } from 'constants/permissions';
import { API_URLS } from 'constants/apiUrls';
import { BACKLINK_PARAM_NAME } from 'constants/common';
import type { IRouterStore } from 'stores';
import { STORES } from 'stores';

import { IProfile, ISystemError } from 'types';
import { get, patch } from 'api/request';
import { redirectToLogin } from 'utils';

const permissionsProfile = [
  'canProposalAdd',
  'canSeeClarifyAlert',
  'isAppraiser',
  'canAddConstructionProject',
] as const;

export type TPermissionsProfile = (typeof permissionsProfile)[number];

export interface IProfileStore {
  user: IProfile | null;
  loading: boolean;
  backlink: string | null;
  systemError: ISystemError | null;
  permissions: { [key in TPermissionsProfile]: boolean };
  isNotEnoughRightsForPage: boolean;
  fetchProfile: () => Promise<void>;
  getBacklink: () => void;
  changeMetadata: (metadata: IProfile['metadata']) => void;
  resetRightsForPage: () => void;
  getUserColumnsConfig(
    route: string,
    codeColumnsConfig: any[],
  ): { sorted: string[]; filtered: string[] } | undefined;
}
@injectable()
export class ProfileStore implements IProfileStore {
  @observable
  user: IProfile | null = null;

  @observable
  loading = false;

  @observable
  backlink: string | null = null;

  @observable
  systemError: ISystemError | null = null;

  @observable
  isNotEnoughRightsForPage = false;

  @inject(STORES.Router)
  private _router: IRouterStore;

  constructor() {
    makeObservable(this);
  }

  @computed
  get permissions(): IProfileStore['permissions'] {
    const permissionsNorm = _.keyBy(this.user?.permissions, item => item);

    const result = permissionsProfile.reduce((acc, permission) => {
      acc[permission] = !!permissionsNorm[PERMISSIONS[permission]];

      return acc;
    }, {} as IProfileStore['permissions']);

    // создавать заявки могут все, потом это может измениться
    return { ...result, canProposalAdd: true };
  }

  @action
  async fetchProfile() {
    try {
      this.loading = true;

      const user: IProfile = await get(API_URLS.profile);

      runInAction(() => {
        this.user = user;
      });
    } catch (error: any) {
      captureException(error);

      if (error.response?.status === 403) {
        redirectToLogin();
        console.warn('[fetchProfile]: Error code 403');
      }
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action
  getBacklink() {
    const backlink = this._router.query[BACKLINK_PARAM_NAME];
    if (typeof backlink === 'string') {
      this.backlink = decodeURIComponent(backlink);
    }
  }

  @action.bound
  async changeMetadata(metadata: IProfile['metadata']) {
    try {
      const requestPayload = {
        metadata: R.mergeDeepRight(this.user?.metadata ?? {}, metadata),
      };

      const user = await patch('/accounts/profile/', requestPayload, {
        disableSuccessMessages: true,
      });

      runInAction(() => {
        this.user = user;
      });
    } catch (error) {
      captureException(error);
      console.error(error);
    }
  }

  @action.bound
  resetRightsForPage() {
    this.isNotEnoughRightsForPage = false;
  }

  getUserColumnsConfig(route: string, codeColumnsConfig: any[]) {
    const allColumnsConfigs = this.user?.metadata?.columns;

    const columns = allColumnsConfigs ? allColumnsConfigs[route] : undefined;

    if (!columns) return;

    const sorted = columns.map(({ id }) => id);
    const filtered = columns.filter(({ active }) => active).map(({ id }) => id);

    // Если что-то изменилось в конфиге, то сбросить настройку и показать дефолтную
    const codeSorted = codeColumnsConfig.map(col => `${col.dataIndex ?? col.id}`);
    if (!R.equals(R.sortBy(R.identity, sorted), R.sortBy(R.identity, codeSorted))) return;

    return { sorted, filtered };
  }
}
