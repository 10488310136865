import { ColumnsType } from 'antd/lib/table';
import { TRriCatalogItem } from 'types';

export enum ECatalogSlug {
  KINDS = 'kinds',
  MATERIALS = 'materials',
  GROUP_MATCHING_MATRIX = 'group-matching-matrix',
  KIND_CLASSIFIER = 'kind-classifier',
  GROUPLOT = 'grouplot',
  STRUCTURES = 'structures-with-types',
  PROJECTS = 'projects',
}

export interface ICatalog {
  slug: ECatalogSlug;
  title: string;
  shortTitle: string;
  columns: ColumnsType<TRriCatalogItem>;
}

export interface IRequestParams {
  search?: string;
  page?: number;
  limit?: number;
}
