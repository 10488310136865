export const API_URLS = {
  profile: '/accounts/profile/',
  classifiers: '/api/standards/classifiers/',
  proposals: '/api/proposals/',
  proposalsSuggest: '/api/proposals/suggest/',
  proposalComments: '/api/proposals/comments/',
  catalog: {
    kinds: '/api/standards/kinds/',
    materials: '/api/standards/onec-materials/',
    'group-matching-matrix': '/api/standards/matching-matrix/',
    'kind-classifier': '/api/standards/kind-classifier/',
    grouplot: '/api/standards/grouplot/',
    'structures-with-types': '/api/standards/structures-with-types/',
    projects: '/api/standards/projects/',
    saas: '/api/proxy-import/',
  },
  prepareExportedCatalog: {
    kinds: '/api/standards/kinds/export_excel/',
    materials: '/api/standards/onec-materials/export_excel/',
    'group-matching-matrix': '/api/standards/matching-matrix/export_excel/',
    'kind-classifier': '/api/standards/kind-classifier/export_excel/',
    grouplot: '/api/standards/grouplot/export_excel/',
    'structures-with-types': '/api/standards/structures-with-types/export_excel/',
    projects: '/api/standards/projects/export_excel/',
  },
  prepareExportedProposals: '/api/proposals/export_excel/',
  projectEventHierarchy: '/api/standards/project-event-hierarchy/',
  exportByTaskId: '/async/api/v1/celery/result/',
  suggestsPrefix: '/api/rri/suggest/',
  sendProposal: '/api/rri/',
  actualSystemError: '/api/obser/',
  checkingAlreadyLot: '/api/standards/grouplot/is-already-exist/',
  changeState: (id: string | number) => `/api/proposals/${id}/change_state/`,
  changeStateFroMetodologist: (
    id: string | number,
  ) => `/api/proposals/${id}/to-previous-stage/
  `,
  changeExecutor: (id: string | number) => `/api/proposals/${id}/change-executor/
  `,
} as const;

export const API_UPLOAD = {
  link: '/api/upload/link/',
  upload: '/upload/',
  attachment: '/api/upload/attachment/',
};
