import {
  ICatalogStore,
  IProfileStore,
  IProposalViewStore,
  STORES,
  useInject,
} from 'stores';
import { IProposalsListStore } from 'stores/ProposalsList';

export const useProfileStore = () => {
  return useInject<IProfileStore>(STORES.Profile);
};

export const useProposalsListStore = () => {
  return useInject<IProposalsListStore>(STORES.ProposalsList);
};

export const useProposalViewStore = () => {
  return useInject<IProposalViewStore>(STORES.ProposalView);
};

export const useCatalogStore = () => {
  return useInject<ICatalogStore>(STORES.Catalog);
};
