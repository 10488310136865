import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import { ColumnsSettingsModalProps } from '@devdept/samolet-ui';

import { useProfileStore } from 'stores';
import { useQuery } from 'hooks';

interface Options {
  columns: any[];
  modalProps: ColumnsSettingsModalProps;
  route: string;
  columnId: 'dataIndex' | 'id';
}

export const useColumnsSettingsSaver = ({
  columns,
  modalProps,
  route,
  columnId,
}: Options) => {
  const profileStore = useProfileStore();

  const prevColumns = usePrevious(columns);
  const [queryChanged, setQueryChanged] = useState(false);
  const { query } = useQuery();

  useEffect(() => {
    setQueryChanged(true);
  }, [query]);

  useEffect(() => {
    if (queryChanged) return setQueryChanged(false);

    const ids = R.pluck(columnId, columns).map(String);
    const prevIds = R.pluck(columnId, prevColumns ?? []);

    const savedColumns = modalProps.columns.map(column => ({
      id: String(column[modalProps.idProp]),
      active: ids.includes(String(column[modalProps.idProp])),
    }));

    const newMetadata = {
      columns: {
        [route]: savedColumns,
      },
    };

    if (!R.equals(ids, prevIds.map(String)) && !R.isNil(prevColumns)) {
      profileStore.changeMetadata(newMetadata);
    }
  }, [
    profileStore,
    columns,
    prevColumns,
    modalProps.columns,
    route,
    columnId,
    queryChanged,
    modalProps.idProp,
  ]);
};
