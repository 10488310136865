import { useCallback, useState } from 'react';

export const useAddModals = () => {
  const [isAddKindModalOpen, setAddKindModalOpen] = useState(false);
  const [isAddMaterialModalOpen, setAddMaterialModalOpen] = useState(false);
  const [isAddProjectDrawerOpen, setAddProjectDrawerOpen] = useState(false);
  const [isEditProjectDrawerOpen, setEditProjectDrawerOpen] = useState(false);

  const toggleModalAddKind = useCallback(() => {
    setAddKindModalOpen(prev => !prev);
  }, []);

  const toggleModalAddMaterial = useCallback(() => {
    setAddMaterialModalOpen(prev => !prev);
  }, []);

  const toggleProjectDrawer = useCallback(() => {
    setAddProjectDrawerOpen(prev => !prev);
  }, []);

  const toggleEditProjectDrawer = useCallback(() => {
    setEditProjectDrawerOpen(prev => !prev);
  }, []);

  return {
    isAddKindModalOpen,
    isAddMaterialModalOpen,
    isAddProjectDrawerOpen,
    isEditProjectDrawerOpen,
    toggleModalAddKind,
    toggleModalAddMaterial,
    toggleProjectDrawer,
    toggleEditProjectDrawer,
  };
};
