import { useEffect, useMemo, useState } from 'react';

import { useColumns } from '@devdept/samolet-ui';

import { useColumnsSettingsSaver } from 'hooks';
import { useProfileStore } from 'stores';

export const useColumnsSettingsModal = (
  allColumns: any[],
  route: string,
  idProp: 'dataIndex' | 'id' = 'dataIndex',
  titleProp = 'title',
) => {
  const profileStore = useProfileStore();

  const defaultColumnsSettings = useMemo(
    () => profileStore.getUserColumnsConfig(route, allColumns),
    [profileStore, route, allColumns],
  );

  const { filteredColumns: columns, modalProps } = useColumns(allColumns, {
    idProp,
    titleProp,
    defaultValues: defaultColumnsSettings,
  });

  useColumnsSettingsSaver({
    columns,
    modalProps,
    columnId: idProp,
    route,
  });

  const [updatedColumns, setColumns] = useState<any[]>(columns);

  useEffect(() => {
    setColumns(columns);
  }, [columns]);

  return {
    modalProps,
    updatedColumns,
    setColumns,
  };
};
