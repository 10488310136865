import { TableProps } from 'antd';
import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { IRriProposalsListItem } from 'types';
import { getSorterValue, setColumnsOrder } from 'utils';
import { useQuery } from 'hooks';

export const useSorting = (setColumns: React.Dispatch<React.SetStateAction<any[]>>) => {
  const { query, pushQuery } = useQuery();

  useEffect(() => {
    const order = query.ordering;
    if (!Array.isArray(order)) {
      setColumns(columns => setColumnsOrder(columns, order));
    }
  }, [query.ordering, setColumns]);

  const handleTableChange: TableProps<IRriProposalsListItem>['onChange'] = useCallback(
    (_pagination, _filters, sorter, extra) => {
      if (extra.action === 'sort' && !Array.isArray(sorter)) {
        const editedSorter = { ...sorter, field: _.snakeCase(String(sorter.field)) };
        const sortValue = getSorterValue(editedSorter);
        pushQuery({ ordering: sortValue, page: 1 });
      }
    },
    [pushQuery],
  );

  return { handleTableChange };
};
