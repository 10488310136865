import { Container } from 'inversify';
import { createContext, useContext } from 'react';

const containerContext = createContext<Container | null>(null);

export const { Provider } = containerContext;

export const useInject = <T>(name: symbol): T => {
  const container = useContext(containerContext);

  if (!container) {
    throw new Error('You have forgotten to wrap app with Provider');
  }

  return container.get<T>(name);
};
