export const STORES = {
  Profile: Symbol('ProfileStore'),
  Router: Symbol('RouterStore'),
  ProposalsList: Symbol('ProposalsListStore'),
  ProposalView: Symbol('ProposalViewStore'),
  Catalog: Symbol('CatalogStore'),
};

export const SERVICES = {
  location: Symbol('location'),
  history: Symbol('history'),
  localStorage: Symbol('localStorage'),
};
