export const menuRoutes = {
  HOME: '/',
  PROPOSALS: '/proposals',
  CATALOG: '/catalog',
  TESTING: '/testing',
  KIND_PROPOSAL: '/kind_proposal',
  // EVENT_PROPOSAL: '/event_proposal',
  MATRIX_PROPOSAL: '/matrix_proposal',
  SPECIALIZATION_PAGE: '/specialization',
  CHANGE_KIND_PAGE: '/change_kind',
  // COPY_MAPPING_PAGE: '/copy_kind_mapping',
  // COPY_WORK_GROUP_PAGE: '/copy_group_mapping',
  CHANGE_STATUS_KIND_PAGE: '/change_status_kind',
  DESCRIPTION_OF_GROUP_PAGE: '/description_of_group',
  MATERIAL_PROPOSAL: '/material_proposal',
  MATERIAL_COPY_PROPOSAL: '/material_copy_proposal',
  PROJECT_PROPOSAL: '/project_proposal',
  CHANGE_PROJECT_PROPOSAL: '/change_project_proposal',
  DELETE_MATRIX_PROPOSAL: '/delete_matrix_proposal',
  DELETE_MATRIX_LOT_PROPOSAL: '/delete_matrix_lot_proposal',
} as const;

export const paths = {
  PROPOSALS_LIST: menuRoutes.PROPOSALS,
  PROPOSAL_VIEW: `${menuRoutes.PROPOSALS}/:id`,
  CATALOG: `${menuRoutes.CATALOG}/:slug`,
  KIND_PROPOSAL: menuRoutes.KIND_PROPOSAL,
  // EVENT_PROPOSAL: menuRoutes.EVENT_PROPOSAL,
  MATRIX_PROPOSAL: menuRoutes.MATRIX_PROPOSAL,
  SPECIALIZATION_PAGE: menuRoutes.SPECIALIZATION_PAGE,
  // COPY_MAPPING_PAGE: menuRoutes.COPY_MAPPING_PAGE,
  // COPY_WORK_GROUP_PAGE: menuRoutes.COPY_WORK_GROUP_PAGE,
  CHANGE_KIND_PAGE: menuRoutes.CHANGE_KIND_PAGE,
  TESTING: menuRoutes.TESTING,
  CHANGE_STATUS_KIND_PAGE: menuRoutes.CHANGE_STATUS_KIND_PAGE,
  DESCRIPTION_OF_GROUP_PAGE: menuRoutes.DESCRIPTION_OF_GROUP_PAGE,
  MATERIAL_PROPOSAL: menuRoutes.MATERIAL_PROPOSAL,
  PROJECT_PROPOSAL: menuRoutes.PROJECT_PROPOSAL,
  CHANGE_PROJECT_PROPOSAL: menuRoutes.CHANGE_PROJECT_PROPOSAL,
  MATERIAL_COPY_PROPOSAL: `${menuRoutes.MATERIAL_COPY_PROPOSAL}/:id`,
  DELETE_MATRIX_PROPOSAL: menuRoutes.DELETE_MATRIX_PROPOSAL,
  DELETE_MATRIX_LOT_PROPOSAL: menuRoutes.DELETE_MATRIX_LOT_PROPOSAL,
};
