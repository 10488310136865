import { Redirect, Route, Switch } from 'react-router-dom';
import React, { Suspense } from 'react';

import { Loader } from '@devdept/samolet-ui';
import { paths } from 'constants/routes';
import ProtectedRoute from 'components/ProtectedRoute';

const ProposalsListPage = React.lazy(() => import('./ProposalsListPage'));
const ProposalViewPage = React.lazy(() => import('./ProposalViewPage'));
const CatalogPage = React.lazy(() => import('./NewCatalogPage'));
const TestingPage = React.lazy(() => import('./TestingPage'));
const ProposalKindPage = React.lazy(() => import('./ProposalKindPage'));
// const ProposalEventPage = React.lazy(() => import('./ProposalEventPage'));
const ProposalMatrixPage = React.lazy(() => import('./ProposalMatrixPage'));
const ProposalSpecializationPage = React.lazy(
  () => import('./ProposalSpecializationPage'),
);
// const CopyMatrixProposalPage = React.lazy(() => import('./CopyMatrixProposalPage'));
// const CopyWorkGroupProposalPage = React.lazy(() => import('./CopyWorkGroupProposalPage'));
const ChangeKindPage = React.lazy(() => import('./ChangeKindPage'));
const ChangeStatusKindPage = React.lazy(() => import('./ChangeStatusKindPage'));
const DescriptionGroupPage = React.lazy(() => import('./DescriptionOfGroupPage'));
const MaterialProposal = React.lazy(() => import('./MaterialProposalPage'));
const MaterialCopyProposal = React.lazy(() => import('./MaterialCopyProposalPage'));
const ProjectProposal = React.lazy(() => import('./ProjectProposalPage'));
const ChangeProjectProposal = React.lazy(() => import('./ChangeProjectPage'));
const DeleteMatrixProposal = React.lazy(() => import('./DeleteMatrixProposalPage'));
const DeleteMatrixLotProposal = React.lazy(() => import('./DeleteMatrixLotProposalPage'));

const Routes: React.FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={paths.PROPOSALS_LIST} component={ProposalsListPage} />
        <ProtectedRoute path={paths.PROPOSAL_VIEW} component={ProposalViewPage} />
        <Route path={paths.CATALOG} component={CatalogPage} />
        <ProtectedRoute path={paths.TESTING} component={TestingPage} />
        <Route path={paths.KIND_PROPOSAL} component={ProposalKindPage} />
        {/* <Route path={paths.EVENT_PROPOSAL} component={ProposalEventPage} /> */}
        <Route path={paths.MATRIX_PROPOSAL} component={ProposalMatrixPage} />
        <Route path={paths.SPECIALIZATION_PAGE} component={ProposalSpecializationPage} />
        {/* <Route path={paths.COPY_MAPPING_PAGE} component={CopyMatrixProposalPage} /> */}
        {/* <Route path={paths.COPY_WORK_GROUP_PAGE} component={CopyWorkGroupProposalPage} /> */}
        <Route path={paths.CHANGE_KIND_PAGE} component={ChangeKindPage} />
        <Route path={paths.CHANGE_STATUS_KIND_PAGE} component={ChangeStatusKindPage} />
        <Route path={paths.DESCRIPTION_OF_GROUP_PAGE} component={DescriptionGroupPage} />
        <Route path={paths.MATERIAL_PROPOSAL} component={MaterialProposal} />
        <Route path={paths.MATERIAL_COPY_PROPOSAL} component={MaterialCopyProposal} />
        <Route path={paths.PROJECT_PROPOSAL} component={ProjectProposal} />
        <Route path={paths.CHANGE_PROJECT_PROPOSAL} component={ChangeProjectProposal} />
        <Route path={paths.DELETE_MATRIX_PROPOSAL} component={DeleteMatrixProposal} />
        <Route
          path={paths.DELETE_MATRIX_LOT_PROPOSAL}
          component={DeleteMatrixLotProposal}
        />
        <Redirect from='*' to={paths.PROPOSALS_LIST} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
